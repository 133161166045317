<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="section-title text-center mb--20 mb_sm--0">
                    <p>
                        There are also many options for accommodation in central Geelong through AirBnb and Stayz.
                    </p>
                </div>
            </v-col>
        </v-row>
        <v-row class="service-one-wrapper">
            <!-- Start Single Service  -->
            <v-col lg="6"
                   md="6"
                   sm="6"
                   cols="12"
                   v-for="(service, i) in serviceContent"
                   :key="i">
                <div class="standard-service single-service service__style--4 super-center">
                    <div class="content">
                        <h3>{{ service.name }}</h3>
                        <p class="text-center">
                            <a v-if="service.url" :href="service.url" class="url-link">{{service.shorturl}}</a>
                        </p>
                    </div>
                </div>
            </v-col>
            <!-- End Single Service  -->
        </v-row>
    </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
           {
            url: "https://www.airbnb.com.au/",
            shorturl: "airbnb.com.au",
            name: "Airbnb",
              },
                {
            url: "https://www.stayz.com.au/",
            shorturl: "stayz.com.au",
            name: "Stayz",
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
        numberRef(num) {
            return "tel:" + num;
        }
    },
  };
</script>

<style lang="scss">
    .super-center {
        text-align: center;
    }
</style>
