<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo />
        <!-- End Header Area -->
        <!-- Start Slider Area  -->

        <div class="rn-service-area rn-section-gapDoubleTop bg_color--1">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="section-title text-center mb--20 mb_sm--0">
                            <h2 class="heading-title">Accommodation</h2>
                            <p>
                                If you are looking for somewhere to stay during you visit and don't know where to start, check out some of the places below.
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <!-- End .row -->
                <Hotels class="rn-section-gapBottom" />
                <OnlineHotels class="rn-section-halfGapBottom" />
                <div class="rn-section-gapBottom about-area">
                    <div class="about-wrapper">
                        <CaravanPark>
                            <img slot="thum-img"
                                 class="w-100"
                                 src="../../assets/images/accommodation/caravan.jpg"
                                 alt="About Images" />
                        </CaravanPark>
                    </div>
                </div>
            </v-container>
        </div>

        <FooterTwo />
    </div>
</template>

<script>
  import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
  import Hotels from "../../components/accommodation/Hotels";
  import OnlineHotels from "../../components/accommodation/OnlineHotels";
  import CaravanPark from "../../components/accommodation/CaravanPark";
  import FooterTwo from "../../components/footer/FooterTwo";

  export default {
    components: {
      HeaderOnePageTwo,
      Hotels,
      OnlineHotels,
      CaravanPark,
      FooterTwo,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss">
  .feather-menu {
    color: #1d1d24;
  }
</style>
