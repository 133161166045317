<template>
    <v-container>
        <v-row align="center" class="row--35">
            <v-col lg="4" md="4" cols="12">
                <div class="thumbnail">
                    <slot name="thum-img"></slot>
                </div>
            </v-col>
            <v-col lg="8" md="8" cols="12">
                <div class="about-inner inner">
                    <div class="section-title">
                        <h2 class="heading-title left-align">Caravan Parks</h2>
                        <p class="description left-align">
                            There are a number of caravan parks in and around Geelong for you to visit. We have listed a handful of these below. <br />
                            Please note however that these are not within walking distance of the shuttle bus transport point.
                        </p>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="service-one-wrapper">
            <v-col lg="6"
                   md="6"
                   sm="6"
                   cols="12"
                   v-for="list in listContent" :key="list.id">
                <div class="standard-service single-service service__style--4 super-center">
                    <div class="content">
                        <h3><b>{{ list.name }}</b></h3>
                        <p>
                            {{ list.address }} <br /> {{ list.suburb }}
                        </p>
                    </div>
                </div>
            </v-col>
         </v-row>
    </v-container>
</template>


<script>
  export default {
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "List Style",
            to: "",
            disabled: true,
          },
        ],
        listContent: [
              {
                id: 1,
                icon: "check",
                name: `Discovery Parks Geelong`,
                address: `59 Barrabool Road`,
                suburb: `Belmont 3216`,
              },
              {
                id: 2,
                icon: "check",
                name: `Tasman Holiday Parks Geelong`,
                address: `75 Barrabool Road`,
                suburb: `Belmont 3216`,
              },
              {
                id: 3,
                icon: "check",
                name: `Torquay Foreshore Caravan Park`,
                address: `35 Bell Street`,
                suburb: `Torquay 3228`,
              },
              {
                id: 4,
                icon: "check",
                name: `Barwon Heads Caravan Park`,
                address: `1 Ewing Blyth Drive`,
                suburb: `Barwon Heads 3227`,
              },
                            {
                id: 5,
                icon: "check",
                name: `Riverview Family Caravan Park`,
                address: `Barwon Heads-Ocean Grove Road`,
                suburb: `Ocean Grove 3226`,
            },
            {
                id: 6,
                icon: "check",
                name: `Geelong Showground Caravan Parking`,
                address: `77 Breakwater Road`,
                suburb: `Breakwater 3219`,
              },
          ],
      };
    },


    };
</script>
