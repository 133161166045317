<template>
    <v-row class="service-one-wrapper">
        <!-- Start Single Service  -->
        <v-col lg="6"
               md="6"
               sm="6"
               cols="12"
               v-for="(service, i) in serviceContent"
               :key="i">
                <div class="standard-service single-service service__style--4 super-center">
                    <div class="content">
                        <h3><b>{{ service.name }}</b></h3>
                        <p>
                            {{ service.address }}
                            <br v-if="service.address" />
                            {{ service.suburb }}
                            <br v-if="service.suburb" />
                            <a v-if="service.number" :href="numberRef(service.number)"><div class="icon" v-html="iconSvg('phone')"></div> {{service.number}}</a>
                            <a v-if="service.url" :href="service.url" class="url-link">{{service.shorturl}}</a>
                            <a v-if="service.promo" :href="service.promo" class="url-link">Link for 10% off best available rate</a>
                        </p>
                    </div>
                </div>
        </v-col>
        <!-- End Single Service  -->
    </v-row>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
              {
                url: "https://www.rydges.com/accommodation/melbourne-vic/geelong",
                shorturl: "rydges.com",
                suburb: "Geelong 3220",
                name: "Rydges Geelong",
                address: `Cnr Myers St & Gheringhap St`,
                promo: 'https://www.rydges.com/private-page/wedding-of-jessie-josh/',
                number: '03 5223 6200'
          },
        {
            url: "https://www.novotelgeelong.com.au",
            shorturl: "novotelgeelong.com.au",
            suburb: "Geelong 3220",
            name: "Novotel Geelong",
            address: `10-14 Eastern Beach Road`,
            number: '03 5223 1377'
        },
        {
            url: "https://www.rhotelgeelong.com.au",
            shorturl: "rhotelgeelong.com.au",
            suburb: "Geelong 3220",
            name: "R Hotel Geelong",
            address: `10 Bellerine Street`,
            promo: '',
            number: '03 4206 0500'
              },
                {
            url: "https://www.questapartments.com.au/properties/vic/geelong/quest-geelong",
            shorturl: "questapartments.com.au",
            suburb: "Geelong 3220",
            name: "Quest Geelong",
                    address: `16-18 The Esplanade South`,
            promo: '',
            number: '03 5228 2000'
              }
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
        numberRef(num) {
            return "tel:" + num;
        }
    },
  };
</script>

<style lang="scss">
    .super-center {
        text-align: center;
    }
</style>
